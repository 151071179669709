<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-4">
                                <v-select v-model="vendorModel" :items="vendorLists" default="" item-value="vendor_id" item-text="vendor_name" label="Select Vendor" clearable dense prepend-icon="mdi-factory" :loading="showProgress"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="vendorTypeModel" :items="vendorTypeLists" default="" item-value="vendor_type" item-text="vendor_type_desc" label="Vendor Type" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-6 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            <div class="col-lg-12">
                                <div class="row text-left badgeInfo">
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-wallet"></span> Invoice : IDR {{ sumInvoice ? sumInvoice : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-receipt"></span> Receipt Note : IDR {{ sumReceiptNote ? sumReceiptNote : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-coin"></span> Paid : IDR {{ sumPaid ? sumPaid : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-credit-card"></span> Debt Remaining : IDR {{ sumDebt ? sumDebt : '-'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            
            <div class="col-lg-12 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Result</h5>
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.showDetails`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip @click="showItemDetails(item)" color="blue" small link outlined v-on="on">Details</v-chip>
                                        </template>
                                        <span>Show Detail</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="detailModalLabel">Detail Debt Report of {{ detailModalTitle }}</h5>
                </div>
                <div class="modal-body">
                    
                    <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense></v-data-table>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'E-Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Debt Report',
                disabled: true,
                href: '#',
                },
            ],
            vendorModel: '',
            vendorLists: [],
            vendorTypeModel: '',
            vendorTypeLists: [],
            itemLists: [],
            headers: [
                { text: 'VENDOR NAME', value: 'vendor_name', align: 'left', class: 'primary--text blue lighten-5'  },
                { text: 'INVOICE', value: 'invoice', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'RECEIPT NOTE', value: 'kwitansi', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'PAYMENT', value: 'payment', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'DEBT TOTAL', value: 'hutang', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5'  },
            ],
            itemDetailLists:[],
            headersDetails: [
                { text: 'INV. DATE', value: 'dt_inv', align: 'center', class: 'primary--text blue lighten-5'  },
                { text: 'TR. ID', value: 'tr_id', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'INV. ID', value: 'inv_id', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'DESCR.', value: 'descr', align: 'center', class: 'primary--text blue lighten-5'  },
                { text: 'PAY TERM', value: 'pay_term_desc', align: 'center', class: 'primary--text blue lighten-5'  },
                { text: 'SUB. TOTAL', value: 'amt_subtotal', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'DISC.', value: 'amt_disc', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'PPN.', value: 'amt_ppn', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'TOTAL.', value: 'amt_total', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'RECEIPT. NOTE', value: 'amt_kwitansi', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'PAID', value: 'amt_paid', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'PAID DISC.', value: 'amt_paid_disc', align: 'right', class: 'primary--text blue lighten-5'  },
                { text: 'DEBT. TOTAL', value: 'hutang', align: 'right', class: 'primary--text blue lighten-5'  }
            ],
            sumInvoice: '',
            sumReceiptNote: '',
            sumPaid: '',
            sumDebt: '',
            loadingDatatable: false,
            showProgress: false,
            searchItem: '',
            detailModalTitle: '',
            loadingDatatableDetail: false,
            isMobile: false,
            userGroup: null,
            appl_id: 'E-REPORTS'
        }
    },
    async mounted(){
        
        
        this.initialize()

    },
    methods:{
        
        async initialize(){

            this.showProgress = true
            document.querySelector(".cardGraph").style.display = "none";

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/DebtReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.showProgress = false
                this.vendorLists = res.data.vendor
                this.vendorTypeLists = res.data.vendorType

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
            
        },

        async getData(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "block";
            this.loadingDatatable = true
            this.itemLists = []

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/DebtReport/getData`, { 
                    vendor: this.vendorModel ? this.vendorModel : '',
                    vendorType: this.vendorTypeModel ? this.vendorTypeModel : ''
                },
                { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.result.length != 0){

                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    this.itemLists = res.data.result

                    this.sumInvoice = res.data.invoice
                    this.sumReceiptNote = res.data.receiptNote
                    this.sumPaid  = res.data.payment
                    this.sumDebt = res.data.debt
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async showItemDetails(id){

            this.detailModalTitle = id.vendor_name
            this.itemDetailLists = []

            $('#detailModal').modal('show')

            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/DebtReport/getDataDetails`, { 
                    vendor: id.vendor_id
                },
                { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        }

    }
    
}
</script>

<style scoped>

.v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>